<p-tag styleClass="w-full justify-content-start"
  *ngIf="displayStatus?.severity !== 'default'; else elseBlock"
  [severity]="displayStatus?.severity"
>
  <div class="flex align-items-center gap-2 px-1">
    <i [class]="displayStatus?.icon"></i>
    <span class="text-base white-space-nowrap">
      {{ displayStatus?.label }}
    </span>
  </div>
</p-tag>
<ng-template #elseBlock>
  <div class="text-gray py-1 px-2 rounded-xl text-sm border-1 border-round">
    <div class="flex align-items-center gap-2 px-1 font-bold text-xs">
      <i [class]="displayStatus?.icon"></i>
      <span class="text-base white-space-nowrap">
        {{ displayStatus?.label }}
      </span>
    </div>
  </div>
</ng-template>
