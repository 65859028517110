export enum QuestionStatus {
  DRAFT = 'DRAFT',
  PENDING_VALIDATION_0_2 = 'PENDING_VALIDATION_0_2',
  PENDING_VALIDATION_1_2 = 'PENDING_VALIDATION_1_2',
  VALIDATED = 'VALIDATED',
  SUSPENDED = 'SUSPENDED',
  REJECTED = 'REJECTED'
}

export type QuestionStatusInfo = {
  label: string;
  icon: string;
  severity: string;
};

export const QuestionStatusInfos: {
  [key in keyof typeof QuestionStatus]: QuestionStatusInfo;
} = {
  DRAFT: {
    label: 'En brouillon',
    icon: 'pi pi-circle',
    severity: 'default',
  },
  PENDING_VALIDATION_0_2: {
    label: 'Validation en attente 0/2',
    icon: 'pi pi-sync',
    severity: 'info',
  },
  PENDING_VALIDATION_1_2: {
    label: 'Validation en attente 1/2',
    icon: 'pi pi-sync',
    severity: 'info',
  },
  VALIDATED: {
    label: 'Exploitable',
    icon: 'pi pi-check-circle',
    severity: 'success',
  },
  SUSPENDED: {
    label: 'Suspendue',
    icon: 'pi pi-pause-circle',
    severity: 'warning',
  },
  REJECTED: {
    label: 'Rejetée',
    icon: 'pi pi-minus-circle',
    severity: 'danger',
  },
};
